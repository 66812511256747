import React from 'react';
import styled from 'styled-components';
import { useViewport } from '../../../hooks/useViewport';

import image from '../../../images/statesman_sec4.png';


const Container = styled.div`
  display: flex;
  width: calc(100% + 144px);
  height: 100vh;
  background-color: #f6f4f0;
  margin-left: -144px;
  flex-direction: column;

  img {
    max-width: 90%;
    max-height: 90%;
    margin: auto;
  }

  .caption {
    font-family: 'Lato';
    font-size: 20px;
    margin: auto;
  }
`;

const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #aa4d3b;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

   img {
    max-width: 90%;
    max-height: 90%;
    margin: auto;
  }

  .caption {
    font-family: 'Lato';
    font-size: 3vw;
    margin: auto;
  }
`;

const StatesmanSectionFour = () => {
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    width <= breakpoint ? (
      <MobileContainer>
        <img src={image} />
        <div className="caption">Armstrong holds press conference with Soviet <br /> dissident Mikhail Makarenko, 1982</div>
      </MobileContainer>
    ) : (
        <Container>
          <img src={image} />
          <div className="caption">Armstrong holds press conference with Soviet <br /> dissident Mikhail Makarenko, 1982</div>
        </Container >
      )
  );
}

export default StatesmanSectionFour