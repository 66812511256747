import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';

import StatesmanSectionOne from '../../components/bio/GradiantImage';
import SectionOneBackground from '../../images/statesman-bg.png';
import SectionOneMobileBackground from '../../images/mobile/Statesman.png';

import StatesmanSectionTwo from '../../components/bio/ImageLeftWhiteBGContainer';
import SectionTwoImage from '../../images/statesman_sec2.png';

import StatesmanSectionThree from '../../components/bio/ImageRightOrangeBGConatiner';
import SectionThreeImage from '../../images/statesman_sec3.png';

import StatesmanSectionFour from '../../components/bio/statesman/StatesmanSectionFour';

import StatesmanSectionFive from '../../components/bio/BottomSectionContainer';
import SectionFiveImage from '../../images/statesman_sec5.png';


const Statesman = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Armstrong's life and legacy: Statesman</title>
        <script>
        {`
          (function (s, e, n, d, er) {
            s['Sender'] = er;
            s[er] = s[er] || function () {
              (s[er].q = s[er].q || []).push(arguments)
            }, s[er].l = 1 * new Date();
            var a = e.createElement(n),
                m = e.getElementsByTagName(n)[0];
            a.async = 1;
            a.src = d;
            m.parentNode.insertBefore(a, m)
          })(window, document, 'script', 'https://cdn.sender.net/accounts_resources/universal.js', 'sender');
          sender('a78ebf1b330821')
        `}
      </script>
      </Helmet>
      <Layout stacked={true}>
        <StatesmanSectionOne
          background={SectionOneBackground}
          title={'Statesman'}
          text={'Bill Armstrong was a transformative figure in Colorado politics for 50 years, including service in elective office for almost 30 years, and leadership of the Republican Party as the definitive elder statesman for the rest of his life. He was elected to the Colorado State House at the age of 25 and the State Senate at 27. He was the youngest Senate Majority Leader in state history, then served in the U.S. House of Representatives for 6 years in the 1970s, and two terms as a U.S. Senator. His campaigns energized thousands of volunteers and helped rebuild the Republican Party in the aftermath of Watergate, leading to electoral successes for a generation of new leaders. In the Senate, he chaired the influential Republican Policy Committee. He was a conservative favorite for President of the U.S. at the end of Ronald Reagan’s term, but instead decided to return home to Colorado, modeling by example the “citizen legislator” he had always believed in.'}
          mobileBackground={SectionOneMobileBackground}
        />
        <StatesmanSectionTwo
          image={SectionTwoImage}
          captionBlack={'With President Reagan in the Oval Office, '}
          captionOrange={'1986'}
          text={`As a Senator, he worked on numerous Colorado issues, helping clean up Denver’s notorious brown cloud by amending clean air laws to include high-altitude standards, for example. He pushed through legislation preserving wilderness areas such as Collegiate Peaks, Eagle’s Nest, Holy Cross, Mt. Zirkel, South San Juans, West Elks, Lost Creek, Maroon Bells, and dozens of others. He authored landmark soil conservation legislation to prevent another dust bowl, helped designate the Cache la Poudre as a Wild and Scenic River, and preserved the MacGregor Ranch in Rocky Mountain National Park.`}
        />
        <StatesmanSectionThree
          title={'He pushed for transparency through live TV coverage of the Senate'}
          text={'On the national stage, he led the effort to stop bracket-creep by indexing personal taxes to inflation, which has saved American families millions. He pushed for transparency through live TV coverage of the Senate, and worked tirelessly to require a balanced budget, lower taxes, and less intrusive government. He worked to helped reform entitlements such as welfare and housing subsidies, pushed reforms to keep Social Security and Medicare solvent, and fought to reinstate the G.I. Bill, which is now a permanent benefit of military service. He fought against abuses of human rights in the USSR, took up the cause of religious liberty throughout the world, called attention to the use of slave labor in communist countries, and worked to maintain the world’s strongest military as the ultimate guarantee of peace.'}
          image={SectionThreeImage}
          captionWhite={`GOP Policy Committee Chairman Armstrong, \nPresident Reagan, Vice President Bush, \nMajority Leader Bob Dole, `}
          captionBlack={'1985'}
        />
        <StatesmanSectionFour />
        <StatesmanSectionFive
          image={SectionFiveImage}
          blackCaption={`Armstrong declares Colorado Christian University's 100th Aniversary, `}
          orangeCaption={'2014, the "Year of the Bible"'}
          blackLink={'Click to next page: '}
          orangeLink={'Christian Activist'}
          route="/bio/christian"
        />
      </Layout>
    </>
  )
}

export default Statesman;